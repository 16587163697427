
#overview_data_table {
    width: 100%;
    border-collapse: collapse;
}

#overview_data_table th {
    width: 200px;
    padding: 3px;
    font-size: 9pt;
}
#overview_data_table td {
    padding: 0 4px;
    font-size: 10pt;
}
#overview_data_table th {
    border: 1px solid #000;
    border-collapse: collapse;
}

#overview_data_table td {
    border: 1px solid #000;
    border-collapse: collapse;
}

@media screen {
    #print_header { display: none; }
    #print_footer { display: none; }
}

@media print {
    #print_header { display: block; position: fixed; top: 0; padding: 10px; }
    #print_footer { display: block; position: fixed; bottom: 0; padding: 10px; }
    #print_body { margin-top: 40px; }
}
